import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Facebook from "../images/fb.svg"
import Twitter from "../images/twitter.svg"
import LinkedIn from "../images/in.svg"
import { Link } from "gatsby"
import { navigate } from "@reach/router"

export default function Footer() {
  return (
    <footer className="bg-primary w-full py-6 px-8 bottom-0 left-0">
      <div className="w-full grid grid-cols-1  h-2/3 md:grid-cols-4 md:items-center  gap-4 justify-items-center">
        <div className="flex flex-col justify-start items-start">
          <div>
            <StaticImage
              src="../images/werise-white.png"
              width={90}
              quality={95}
              formats={["auto", "webp", "avif"]}
              alt="Werise logo"
            />
          </div>

          <div className="my-10">
            <p className="text-white text-md font-light">
              Get latest news on campaigns and political leaders in your
              district and counties.{" "}
            </p>
          </div>

          <div className="py-10 flex w-36 justify-between">
            <Facebook /> <Twitter /> <LinkedIn />
          </div>

          <div>
            <p className="text-white text-md font-light">
              Copyright © WeRise {new Date().getFullYear()}
            </p>
          </div>
        </div>
        <div className="flex flex-col justify-between items-start w-full">
          <div className="h-28 flex items-center">
            <p className="text-white text-md font-semibold">WeRise</p>
          </div>

          <div className="my-10 flex flex-col">

            <div className="text-white text-md my-3 font-normal cursor-pointer" onClick={() => navigate("/#about")}>About</div>
            <div className="text-white text-md my-3 font-normal cursor-pointer" onClick={() => navigate("/#features")}>Features</div>
            <div className="text-white text-md my-3 font-normal cursor-pointer" onClick={() => navigate("/candidate")}>Candidates</div>
            <div className="text-white text-md my-3 font-normal cursor-pointer" onClick={() => navigate("/privacy-policy")}>
              Privacy Policy
            </div>
            <div className="text-white text-md my-3 font-normal cursor-pointer">
              Contact us
            </div>
          </div>
        </div>

        <div className="flex flex-col justify-between items-start w-full">
          <div className="h-28 flex items-center">
            <p className="text-white text-md font-semibold cursor-pointer">Contact</p>
          </div>

          <div className="my-10 flex flex-col">
            <div className="text-white text-md my-3 font-normal">
              {" "}
              info@weriseapp.co
            </div>
            <div className="text-white text-md my-3 font-normal">
              support@weriseapp.co
            </div>
            <div className="text-white text-md my-3 font-normal">
            <Link href="/delete-account">
              account
              </Link>
            </div>
            <div className="text-white text-md my-3 font-normal"></div>
            <div className="text-white text-md my-3 font-normal"></div>
            <div className="text-white text-md my-3 font-normal"></div>
            <div className="text-white text-md my-3 font-normal"></div>
            <div className="text-white text-md my-3 font-normal"></div>
          </div>
        </div>

        <div className="flex items-end h-full justify-end">
          <div>
            <p className="text-white cursor-pointer text-md font-light">
              <Link href="https://www.noirtech.digital/">
              Developed by Noirtech
              </Link>
            </p>
          </div>
        </div>
      </div>
    </footer>
  )
}
